import React from 'react';

const CakePricing = (cake) => {
    const cakeObject = cake.cake;

    const formatPrice = (price) => {
        if (price == null) {
            return;
        }

        return `${price.toFixed(2).replace('.', ',')}€`;
    };

    const formatUnit = (cake, number = 1) => {
        let unit;

        if (!cake.unit) {
            unit = 'part'
        } else {
            unit = cake.unit.unit
        }

        if (number > 1) {
            return pluralize(unit, cake.minimumSlices);
        }

        return unit;
    };

    const minimumOfSlices = (cake) => {
        const defaultMinimumSlice = '6';

        if (!cake.minimumSlices) {
            return `A partir de ${defaultMinimumSlice} ${formatUnit(cake, defaultMinimumSlice)}`
        }


        return `A partir de ${cake.minimumSlices} ${formatUnit(cake, cake.minimumSlices)}`
    };

    const pluralize = (word, count, locale = 'fr') => {
        const rules = new Intl.PluralRules(locale);
        const category = rules.select(count);
      
        const plurals = {
          pièce: { one: "pièce", other: "pièces" },
          part: { one: "part", other: "parts" },
        };
      
        return plurals[word] && plurals[word][category] ? plurals[word][category] : word;
      };

    const displayPrice = (cake) => {
        const pricePerSlice = cake.pricePerSlice;
        const priceForQuantities = cake.priceForQuantities;
        if (pricePerSlice) {
            return (
                <>
                    {formatPrice(pricePerSlice)}/{formatUnit(cake)}
                    <br/><br/>
                    <b>{minimumOfSlices(cake)}</b>
                </>
            );
        }

        if (!priceForQuantities) {
            return;
        }

        return (
            <>
                <ul>
                    {priceForQuantities.map((price, index) => (
                        <li key={index}>{formatPrice(price.price)} pour {price.quantity} {formatUnit(cake, price.quantity)}</li>
                    ))}
                </ul>
            </>
        )

    }
    
    return (
        <>
            {displayPrice(cakeObject)}
        </>
    )
}

export default CakePricing;